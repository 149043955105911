import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const getAllTickets = createAsyncThunk(
    'company/tickets/show-all',
    async (payload, thunkAPI,) => {

        const { company_id } = payload;

        try {
            const res = await fetch(`/api/company/tickets/show-all/${company_id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                console.log("tickets/show/public != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("tickets/show/public Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);