import React, { useState, useEffect, useMemo } from 'react';
import { ExpandSvg } from 'containers/Icons'; // Assuming other icons are not needed anymore
import { useTranslation } from 'react-i18next';

const TicketsAdminList = ({ tickets, onRowClick, handleDetails, selectedRow }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRowId, setSelectedRowId] = useState(null); // Initialize selectedRowId to null
  const [selectedRow_inner, setSelectedRow_inner] = useState(null); // Initialize selectedRowId to null
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Handle row click and update selected row ID
  const handleRowClick = (ticket) => {
    onRowClick(ticket);          // Trigger the callback with the clicked ticket data
    setSelectedRow_inner(ticket)
  };

  // Update the selected row when selectedRow prop changes
  //useEffect(() => {
  //  if (selectedRow) {
  //    setSelectedRowId(selectedRow.id);  // Set selectedRowId when prop changes
  //  }
  //}, [selectedRow_inner]); // Update selectedRowId whenever selectedRow changes

  useEffect(() => {

    if (selectedRow) {
      setSelectedRowId(selectedRow.ticket_id);
    }
  }, [selectedRow, handleRowClick]);

  const normalizeString = (str) => {
    if (!str) return '';
    return str
      .replace(/[Šš]/g, 's')
      .replace(/[Žž]/g, 'z')
      .replace(/[ČĆčć]/g, 'c')
      .replace(/[Đđ]/g, 'd')
      .toLowerCase();
  };

  // Filter and sort logic
  const filteredAndSortedTickets = useMemo(() => {
    const normalizedQuery = normalizeString(searchQuery);
    let sortableItems = tickets.filter(ticket =>
      (normalizeString(ticket.first_name).includes(normalizedQuery) || 
       normalizeString(ticket.last_name).includes(normalizedQuery))
    );
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === 'tickets_count' || sortConfig.key === 'tickets_verified') {
          return sortConfig.direction === 'ascending' ? a[sortConfig.key] - b[sortConfig.key] : b[sortConfig.key] - a[sortConfig.key];
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
          if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
          return 0;
        }
      });
    }
    return sortableItems;
  }, [tickets, searchQuery, sortConfig]);

  // Handle sorting logic
  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Determine the sort indicator (arrows) for table headers
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return ' -';
  };

  return (
    <div style={{ borderRadius: "12px", backgroundColor: "white", marginBottom: "20px", padding: "15px" }}>
      <div className="input-field">
        <input
          className="input"
          type="text"
          placeholder={t('name_surname_gsm')}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <label className="label" htmlFor="about_us">{t('search_label')}</label>
      </div>

      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
          <thead style={{ fontSize: '13px' }}>
            <tr>
              <th>#</th>
              <th>Id</th>
              <th onClick={() => requestSort('first_name')}>Ime{getSortIndicator('first_name')}</th>
              <th onClick={() => requestSort('last_name')}>Prezime{getSortIndicator('last_name')}</th>
              <th style={{maxWidth: "70px"}}>Pregledaj Poništi</th>
              <th onClick={() => requestSort('tickets_count')} style={{maxWidth: "50px"}}>Br. Ulaznca{getSortIndicator('tickets_count')}</th>
              <th onClick={() => requestSort('tickets_verified')} style={{maxWidth: "50px"}} >Poništeno{getSortIndicator('tickets_verified')}</th>
              <th>Kategorija</th>
             
            </tr>
          </thead>
          <tbody style={{ fontSize: '13px' }}>
            {filteredAndSortedTickets.map((ticket, index) => (
              <tr key={ticket.ticket_id} onClick={() => {handleRowClick(ticket)}}
                style={{
                  cursor: 'pointer',
                  borderTop: '1px solid #ddd',
                  backgroundColor: Number(ticket.ticket_id) === Number(selectedRowId) ? '#FDF7EE' : (index % 2 === 0 ? '#FCFCFD' : 'white')
                }}>
                <td>{index + 1}.</td>
                <td>{ticket.ticket_id || 'N/A'}</td>
                <td>{ticket.first_name || 'N/A'}</td>
                <td>{ticket.last_name || 'N/A'}</td>
                <td>
                  <button
                    className="edit-button"
                    onClick={(e) => {
                      e.stopPropagation();  // Prevent the row's onClick from firing
                      handleDetails(ticket);
                    }}
                    style={{ maxWidth: '30px', height: '30px', paddingTop: '3px' }}
                  >
                    <label style={{ width: "24px", height: "24px", marginLeft: "-11px" }}><ExpandSvg fill1="white" /></label>
                  </button>
                </td>
                <td>{ticket.tickets_count}</td>
                <td>{ticket.tickets_verified}</td>
                <td>{ticket.menu_name || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketsAdminList;
