import Layout from "components/Layout";
import React, { useState } from 'react';
import NumberPad from "../NumberPad";
import { useTranslation } from 'react-i18next';
import boat from "./boat.jpg"


const GlassPanelWithChildRegatta = ({ formData,  child}) => {
  const { t } = useTranslation();
    return (    
        <>
        <div className="gp_container" style={{backgroundImage: `url(${boat})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: "100%", height: "100%", padding: "0", margin: "0", boxSizing: "border-box" }}>
              <div className="gp_box">
                <h1 className="gp_title"></h1>
                <h1 className="gp_sub_title" style={{minHeight:"70px"}}></h1>
                <h1 className="gp_sub_title" style={{paddingLeft: "5px" }}>ULAZNICA</h1>
                <div className="gp_content" >
                  {child}
                </div>
                <div>
                  <strong>{formData?.name ?? ""}</strong>
                  <p>{formData?.about_us ?? ""}</p>
                  <span>Split, Hrvatska: 10.10.2024 - 11.10.2024</span>
                </div>
              </div>
            </div>
        </>
    );
};

export default GlassPanelWithChildRegatta;