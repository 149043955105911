import { createSlice } from '@reduxjs/toolkit';
import { companyExistenceCheck } from './publicSys/CompanyExistenceCheck';
import { cardMenuViewAllPublic } from './cardMenuSys/CardMenuViewAllPublic';
import { companyPublicData } from './publicSys/CompanyPublicData';
import { verifyContactA } from './publicSys/VerifyContactA';
import { verifyReservation } from './publicSys/VerifyReservation';
import { reservationPublicShow } from './publicSys/ReservationPublicShow';
import { reservationPublicVerify } from './publicSys/ReservationPublicVerify';
import { ticketViewPublic } from './publicSys/TicketViewPublic';
import { getAllTickets } from './publicSys/GetAllTickets';


const initialState = {
    loadingCompanyExists: false,
    companyExists: null,
    loading: false,
    menuCardList: null,
    companyData: null,
    requestStarted: false,
    reservationError: false,
    contactId: null,
    loadingReservation: false,
    reservationDoesNotExists: false,
    reservation: null,
    reservationVerified: false,
    ticket: null,
    createdReservation: null,

    language: '',

    ticket_list_full: [],
    ticket_list_change: false,
};

const publicSlice = createSlice({
    name: 'public', // Adjusted the name for clarity, change as needed
    initialState,
    reducers: {
        resetCreatedReservation: state => {
            state.createdReservation = false;
        },

        resetContactId: state => {
            state.contactId = null
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        resetReservationError: (state, action) => {
            state.reservationError = false;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllTickets.pending, (state) => {
                state.loadingReservation = true;
            })
            .addCase(getAllTickets.fulfilled, (state, action) => {
                state.loadingReservation = false;
                state.ticket_list_full = action.payload;
            })
            .addCase(getAllTickets.rejected, (state) => {
                state.loadingReservation = false;
                state.ticket_list_full = [];
            })
            .addCase(ticketViewPublic.pending, (state) => {
                state.loadingReservation = true;
            })
            .addCase(ticketViewPublic.fulfilled, (state, action) => {
                state.loadingReservation = false;
                state.ticket = action.payload;
            })
            .addCase(ticketViewPublic.rejected, (state) => {
                state.loadingReservation = false;
                state.reservationError = true;
            })
            .addCase(reservationPublicVerify.pending, (state) => {
                state.loadingReservation = true;
            })
            .addCase(reservationPublicVerify.fulfilled, (state, action) => {
                state.loadingReservation = false;
                state.reservationVerified = true;
            })
            .addCase(reservationPublicVerify.rejected, (state) => {
                state.loadingReservation = false;
                state.reservationError = true;
            })
            .addCase(reservationPublicShow.pending, (state) => {
                state.loadingReservation = true;
            })
            .addCase(reservationPublicShow.fulfilled, (state, action) => {
                state.loadingReservation = false;
                state.reservation = action.payload;
            })
            .addCase(reservationPublicShow.rejected, (state) => {
                state.loadingReservation = false;
                state.reservationDoesNotExists = true;
            })

            .addCase(verifyReservation.pending, (state) => {
                state.requestStarted = true;
                state.createdReservation = null;
            })
            .addCase(verifyReservation.fulfilled, (state, action) => {
                state.requestStarted = false;
                state.reservationError = false;
                state.createdReservation = action.payload;
            })
            .addCase(verifyReservation.rejected, (state) => {
                state.requestStarted = false;
                state.reservationError = true;
            })

            .addCase(verifyContactA.pending, (state) => {
                state.requestStarted = true;
                state.contactId = null;
                //console.log("verifyContactA počeo");
            })
            .addCase(verifyContactA.fulfilled, (state, action) => {
                state.requestStarted = true;
                state.contactId = action.payload;
                //console.log("verifyContactA HAPY");
            })
            .addCase(verifyContactA.rejected, (state) => {
                state.requestStarted = false;
                state.contactId = null;
                //console.log("verifyContactA sad");
            })

            .addCase(companyPublicData.pending, (state) => {
                state.loadingCompanyExists = true;
            })
            .addCase(companyPublicData.fulfilled, (state, action) => {
                state.loadingCompanyExists = false;
                state.companyData = action.payload;
            })
            .addCase(companyPublicData.rejected, (state) => {
                state.loadingCompanyExists = false;
                state.companyData = null; // Consider setting to null or maintaining the previous state
            })
            .addCase(companyExistenceCheck.pending, (state) => {
                state.loadingCompanyExists = true;
            })
            .addCase(companyExistenceCheck.fulfilled, (state, action) => {
                state.loadingCompanyExists = false;
                state.companyExists = action.payload;
            })
            .addCase(companyExistenceCheck.rejected, (state) => {
                state.loadingCompanyExists = false;
                state.companyExists = null; // Consider setting to null or maintaining the previous state
            })
            .addCase(cardMenuViewAllPublic.pending, state => {
                state.loading = true;
            })
            .addCase(cardMenuViewAllPublic.fulfilled, (state, action) => {
                state.loading = false;
                state.menuCardList = action.payload;
            })
            .addCase(cardMenuViewAllPublic.rejected, state => {
                state.loading = false;
            });
    }
});

// Export actions from this slice if you have any. For example:
export const { resetCreatedReservation, resetContactId, setLanguage, resetReservationError } = publicSlice.actions;

// Export the reducer
export default publicSlice.reducer;
