import React, { useState, useEffect } from 'react';
import Layout from "components/Layout";
import Pic from "containers/images/minit_logo.jpg";
import { useDispatch, useSelector } from 'react-redux';

const FlipPanel = ({ child1, child2, isFlipped }) => {

    const [isFlipped_local, setIsFlipped_local] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setIsFlipped_local(isFlipped);

    }, [dispatch, isFlipped]);

    return (
        <div className="nf_container">
            <div className="nf_card">
                <div className={`nf_content ${isFlipped_local ? 'nf_flipped' : ''}`}>
                    
                    {/* Back Side */}
                    <div className="nf_back">
                        <div 
                            className="nf_back-content" 
                            style={{ 
                                backgroundImage: `url(${Pic})`, 
                                backgroundSize: 'cover', 
                                backgroundRepeat: 'no-repeat', 
                                backgroundPosition: 'center', 
                                width: "100%", 
                                height: "100%" 
                            }}>
                            {child1} {/* Content for back side */}
                        </div>
                    </div>
    
                    {/* Front Side */}
                    <div className="nf_front">
                        <div 
                            className="nf_front-content" 
                            style={{ 
                                backgroundImage: `url(${Pic})`, 
                                backgroundSize: 'cover', 
                                backgroundRepeat: 'no-repeat', 
                                backgroundPosition: 'center', 
                                width: "100%", 
                                height: "100%" 
                            }}>
                            {child2} {/* Content for front side */}
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    );
};

export default FlipPanel;
