import React, { useState, useEffect } from 'react';
import Layout from "components/Layout";
import Pic from "containers/images/minit_logo.jpg";
import { useDispatch, useSelector } from 'react-redux';

const FlipPanel = ({ child1, child2, isFlipped }) => {

    const [isFlipped_local, setIsFlipped_local] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setIsFlipped_local(isFlipped);

    }, [dispatch, isFlipped]);

    return (
        <>
            <div className="fpp_card">
                <div className={`fp_content ${isFlipped_local ? 'flipped' : ''}`}> 
                    <div className="fp_back">
                        <div className="fp_back-content" style={{ backgroundImage: `url(${Pic})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: "99.5%", height: "99.5%"}}>
                            {child1}
                        </div>
                    </div>
                    <div className="fp_front">

                    <div className="fp_back-content" style={{ backgroundImage: `url(${Pic})`,  backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: "99.5%", height: "99.5%" }}>
                            {child2}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default FlipPanel;
