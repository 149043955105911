import Layout from "components/Layout";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import FlipPanel from "./components/cards/FlipPanel";
import GlassPanelWithChild from "./components/cards/GlassPanelWithChild";
import React, { useState, useEffect } from 'react';
import { companyExistenceCheck } from "features/publicSys/CompanyExistenceCheck";
import { companyPublicData } from "features/publicSys/CompanyPublicData";
import ModalWithChildren from "containers/modals/ModalWithChildren";
import ReservationUserVerify from "./components/ReservationUserVerify";
import { reservationPublicVerify } from "features/publicSys/ReservationPublicVerify";
import { reservationPublicShow } from "features/publicSys/ReservationPublicShow";
import { ticketViewPublic } from "features/publicSys/TicketViewPublic";
import { ticketUpdate } from "features/ticketSys/TicketUpdate";
import { getUser } from "features/user";
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { resetTicketUpdated } from "features/ticket";

const VerifyReservationPage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cs, companyName, companyId, resId } = useParams();

    const { user, company } = useSelector(state => state.user);
    const [singleton, setSingleton] = useState(false);
    const [singleton2, setSingleton2] = useState(false);
    const [singleton3, setSingleton3] = useState(false);
    const [invalidData, setInvalidData] = useState(false);

    // Accessing query parameters
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tid = searchParams.get('tid');

    const { ticketUpdated } = useSelector(state => state.ticket);
    const { loadingCompanyExists, companyExists } = useSelector(state => state.public);
    //ContactId ti vjv nece trebat
    const { companyData, reservation, reservationError, requestStarted, loadingReservation, reservationDoesNotExists, ticket } = useSelector(state => state.public);

    const [loadingCancelingTicket, setLoadingCancelingTicket] = useState(false);
    const [valid, setValid] = useState(true);

    const [reservationData, setReservationData] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [rezError, setRezError] = useState(false);
    const [ticketText, setTicketText] = useState("");
    const [sentValue, setSentValue] = useState("");
    const [showVerifyTicketBtn, setShowVerifyTicketBtn] = useState(false);


    const formatDateFromYyyymmdd = (d) => {
        const yyyymmdd = d ? d.replace(/-/g, "") : "";
        const year = yyyymmdd.substring(0, 4);
        const month = yyyymmdd.substring(4, 6);
        const day = yyyymmdd.substring(6, 8);
        return `${day}.${month}.${year}`;
    };

    const [cardData, setCardData] = useState({

        name: companyData?.name ?? "",
        about_us: companyData?.about_us ?? "",
        address: companyData?.address ?? "",

        selected_date: '',
        listItemInput: ''
    });


    // State to track whether the card is flipped
    const [isFlipped, setIsFlipped] = useState(false);

    const generateChecksum = (inputString) => {
        const sum = inputString.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const checksum = sum % 10000;
        return checksum;
    };

    //Data Validity
    useEffect(() => {
        if (cs, companyName, companyId, resId && !singleton3) {

            const checksumInput = `${companyName}${companyId}${resId}`;
            const checksum = generateChecksum(checksumInput);

            if (Number(cs) != Number(checksum)) {
                setInvalidData(true);
            }

            setSingleton3(true);
        }

    }, [cs, companyName, companyId, resId, singleton3]);

    //Dovuci usera
    useEffect(() => {
        if (tid && !singleton) {
            setSingleton(true);
            dispatch(getUser());
        }

    }, [tid, singleton]);

    //Provjeri dali je iz iste firme
    useEffect(() => {
        console.log("user: " + user)
        console.log("company: " + company)
        if (user && company && !singleton2) {
            setSingleton2(true);
            if (Number(companyId) === company.id) {
                setShowVerifyTicketBtn(true);
            }
        }

    }, [dispatch, user, company]);

    // Provijeri postoji li
    useEffect(() => {
        const payload = {
            company_slug: companyName,
            company_id: companyId,
        };
        dispatch(companyExistenceCheck(payload));
    }, [dispatch, companyName, companyId]); // Removed applyValidity from dependencies

    //dohvati podatke za kompaniju
    useEffect(() => {
        if (companyExists && !companyData) {
            const payload = {
                company_id: companyId,
            };
            dispatch(companyPublicData(payload));
        }
    }, [dispatch, companyExists, companyData, companyId]);

    //Provjeri rezervaciju
    useEffect(() => {
        if (companyExists && !reservation) {
            const payload = {
                id: resId,
                company_id: companyId,
            };
            dispatch(reservationPublicShow(payload));
        }
    }, [dispatch, reservation, companyData, reservationData]);

    useEffect(() => {
        //console.log("reservation=====>" + JSON.stringify(reservation));
        setReservationData(reservation);
    }, [dispatch, reservation]);

    useEffect(() => {
        setCardData({
            name: companyData?.name ?? "",
            about_us: companyData?.about_us ?? "",
            address: companyData?.address ?? "",
            selected_date: reservationData ? formatDateFromYyyymmdd(reservationData.reservation_date) : "",
            listItemInput: '',
        });
    }, [companyData, reservationData]);


    // Function to toggle the flip state
    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    useEffect(() => {
        if (companyExists !== null && reservationData != null) {
            setValid(companyExists.exists);
        }
    }, [companyExists, reservationData]);

    useEffect(() => {
        if (reservationError) {
            setRezError(reservationError);
        }
    }, [reservationError]);

    useEffect(() => {
        if (reservation) {

            const payload = {
                reservation_id: reservation.id
            };

            dispatch(ticketViewPublic(payload));
        }
    }, [dispatch, reservation, companyData, reservationData]);

    useEffect(() => {
        if (!ticket) return;
        let available = 0;
        let count = 0;
        let id = 0;

        ticket.map(ticket => {
            available = ticket.tickets_count - ticket.tickets_verified + available;
            count = ticket.tickets_count + count;
            id = ticket.id;
        });

        if (available <= 0) {
            setShowVerifyTicketBtn(false);
        }

        //companyName, companyId, resId
        setSentValue(`${process.env.REACT_APP_MEDIA}/reservation/verify/${cs}/${companyName}/${companyId}/${resId}/?tid=${id}`)
        setTicketText(`${t('tickets_available')}: ${available} ${t('from')}: ${count}`)
    }, [ticket]);


    const pressBackMenuResConfirm = () => {
        handleFlip();
    };

    //OVDJE SREDI UNOS DATUMA
    const info = () => {
        const finalInfo = {
            nameAndSurname: reservationData ? reservationData.name + " " + reservationData.surname : "",
            gsm: reservationData ? reservationData.phone_prefix + reservationData.phone_number : "",
            peopleCount: reservationData ? reservationData.people_num : "",
            menu: reservationData ? reservationData.menu_name : "",
            timeSpan: reservationData ? reservationData.time_from + " - " + reservationData.time_to : "",
            onDate: reservationData ? formatDateFromYyyymmdd(reservationData.reservation_date) : "",
        };

        return finalInfo;
    };



    const sendReservationRequest = () => {

        const payload = {
            id: reservationData.id,
        };

        dispatch(reservationPublicVerify(payload));
        handleFlip();

    };

    const showConfirmButton = () => {
        //Prikazuje button da Gost potbrdi kartu
        if (reservationData && reservationData.user_confirmation == true) {
            return false;
        }

        return true;
    };

    const cancelTicketMethod = () => {
        if (!tid || !ticket || !ticket[0].id) {
            console.log("Neispravna ulaznica 1" + JSON.stringify(ticket[0].id));
            return;
        }
        if (Number(tid) !== Number(ticket[0].id)) {
            console.log("Neispravna ulaznica 2" + JSON.stringify(ticket[0].id));
            return;
        }

        const tv = Number(ticket[0].tickets_verified) + 1;

        const payload = {
            id: tid,
            company_id: companyId,
            tickets_verified: tv,
        };

        dispatch(ticketUpdate(payload));
        setLoadingCancelingTicket(true);
        setIsFlipped(!isFlipped);

    };


    const clickFlip = () => {
        if (showConfirmButton()) {
            return;
        }

        setIsFlipped(!isFlipped);
    };

    useEffect(() => {
        if (ticketUpdated) {

            const payload = {
                reservation_id: reservation.id
            };

            dispatch(ticketViewPublic(payload));
            dispatch(resetTicketUpdated());
            setLoadingCancelingTicket(false);
        }
    }, [dispatch, ticketUpdated]);


    const reportMessage = () => {

        if (loadingReservation || loadingCancelingTicket) {
            return (
                <div className="number-pad" >
                    <span style={{ display: 'block', width: '260px' }}></span>
                    <div style={{ maxWidth: "250px", marginLeft: "-360x" }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35vh' }}>
                            <div className="spinner-border text-primary mt-4" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>

                        </div>
                    </div>
                </div>);
        }

        if (rezError) {
            return (<><div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35vh' }} >Greška kod slanja  potvrde rezervacije.</div></>);
        } else {
            return (<>
                <div className="number-pad" >
                    <span style={{ display: 'block', width: '260px' }}></span>
                    <div style={{ maxWidth: "250px", marginLeft: "-325px", marginBottom: "-20px" }}>
                        <p style={{ marginBottom: "-50px" }}>{t("reservation_confirmed")}</p>
                        <span style={{ display: 'block', height: '10px' }}></span>
                        <div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35vh', marginTop: '20px' }} >
                            <QRCode value={sentValue} size={325} bgColor={"#ffffff"} fgColor={"#000000"} level={"H"} includeMargin={true} />
                        </div>
                    </div>
                </div>
            </>
            );
        }

    };

    const getChildComponent = (tabIndex) => {
        switch (tabIndex) {
            case 0:
                return <GlassPanelWithChild formData={cardData} child={<ReservationUserVerify info={info} sendReservationRequest={sendReservationRequest} showButton={showConfirmButton} showCancelTicket={showVerifyTicketBtn} ticketText={ticketText} cancelTicketMethod={cancelTicketMethod} />} />;

            // Add more cases as needed
            default:
                return <GlassPanelWithChild formData={cardData} child={
                    <>
                        {requestStarted ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <div className="spinner-border text-primary mt-4" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>

                            </div>

                        ) : (
                            <>
                                {reportMessage()}
                            </>
                        )}
                    </>
                } />;
        }
    };

    if ((loadingCompanyExists || loadingReservation) && !singleton2) {
        return (

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className="spinner-border text-primary mt-4" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>

            </div>

        );
    } else if (valid === false || reservationDoesNotExists || invalidData) {
        return (
            <Layout title='Sonex | Potvrdi rezervaciju' content='Not found'>
                <h1>404 Not Found</h1>
            </Layout>
        );
    } else if (valid && reservationData) {
        return (
            <Layout title={`Verify Reservation for ${companyName}`}>
                <span style={{ display: 'block', height: '50px' }}></span>
                <div onClick={clickFlip} style={{ display: 'flex', justifyContent: 'center', alignItems: 'top', height: '100vh' }}>
                    <FlipPanel
                        child1={getChildComponent(tabIndex)}
                        child2={getChildComponent(tabIndex + 1)}
                        isFlipped={isFlipped}
                    />
                </div>

                <ModalWithChildren isOpen={showModal} onClose={() => setShowModal(false)} renderSave={false} renderDelete={false}>

                </ModalWithChildren>
            </Layout>
        );
    } else {
        // Return null or a loading spinner if the validity has not been determined yet.
        return <div>Loading...</div>;
    }
};

export default VerifyReservationPage;